import React from "react";
import "../styles/blog-template.css";
import Layout from "../components/layout";
import { posts } from "../constants/blog-posts";

const blogPost = ({ pageContext }) => {
  const post = posts[pageContext.id];
  return (
    <Layout
      title={"Joeli Toscano | " + post.title}
      description={post.paragraphs[0].content}
    >
      <div className="post-container">
        <div className="post-header">
          <h1>{post.title}</h1>
          <div className="post-img">{post.image}</div>
          <div className="post-info">
            <h2>By: Joeli Toscano</h2>
            <h2>{post.date}</h2>
          </div>
        </div>
        <div className="post-content">
          {post.paragraphs.map((content) => (
            <div key={content.content}>
              <br />
              {content.content}
              <br />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default blogPost;
